import jQuery from 'jquery';
window.$ = jQuery;

jQuery(document).ready(function($){
    const body = $('body');

    /** Show sub nav **/
    body.on('click', '.have-sub', function(e) {
        if ( $(this).hasClass('visible') ) {
            $(this).removeClass('visible').next().removeClass('visible');
        } else {
            $('.have-sub').removeClass('visible').next().removeClass('visible');
            $(this).addClass('visible').next().addClass('visible');
        }
    });
    body.on('click', '.back-menu', function (e) {
        $('.have-sub').removeClass('visible').next().removeClass('visible');
    });
    body.on('mouseleave', '.header', function(e) {
        $('.have-sub').removeClass('visible').next().removeClass('visible');
    });

    /** Toggle mobile nav **/
    body.on('click', '.toggle-nav', function(e) {
        $(this).toggleClass('open');
        $('.header__container--inner-navigation').toggleClass('visible');
        $('.have-sub').removeClass('visible').next().removeClass('visible');
        body.toggleClass('no-scroll')
    });
});
